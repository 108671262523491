import { Resolve } from '@angular/router';
import {
    Injectable
} from '@angular/core';
import {
    IntegrationsSettingsModel,
    MsExchangeBuildingCollectionModel
} from '@app/core';

@Injectable()
export class MsExchangeBuildingsResolver implements Resolve<boolean> {

    constructor(
        private integrationsSettingsModel: IntegrationsSettingsModel,
        private readonly model: MsExchangeBuildingCollectionModel
    ) { }

    async resolve(): Promise<boolean> {

        await this.model.init(this.integrationsSettingsModel.customerId);
        return Promise.resolve(true);
    }
}
