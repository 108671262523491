import { Resolve } from '@angular/router';
import {
    Injectable
} from '@angular/core';
import {
    IntegrationsSettingsModel
} from '@app/core';

@Injectable()
export class IntegrationsResolver implements Resolve<boolean> {

    constructor(

        private readonly model: IntegrationsSettingsModel
    ) { }

    async resolve(): Promise<boolean> {

        await this.model.init();
        return Promise.resolve(true);
    }
}
