import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IntegrationsRes } from '@app/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IntegrationsResResolver implements Resolve<Observable<void>> {

    constructor(private res: IntegrationsRes) {

    }

    public resolve(): Observable<void> {
        return this.res.initObservable();
    }
}
